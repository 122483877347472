import React from 'react';
import '../../App.css';
import pic from '../hizmetlerimiz.jpg';
import Footer from '../Footer';
import Cards from '../Cards';
import HeroSection from '../HeroSection';

import bakın from '../bakın';


 function Services() {
  



  return (
    <>
    
     
      
      <Cards />

      <Footer />


      

      
    </>
  );
}













    
    
    
       

    
    

     
 

export default Services;
