import React from 'react';
import '../../App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Button } from '../Button';
import { Link } from 'react-router-dom';

import ReactDOM from "react-dom";
import Footer from '../Footer';




function bilgilendirme() {
  return (
    
   <div>
<div class="container">
  
    <div class="row" >
      <div class="col-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12446.264760951313!2d35.362182!3d38.750716!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15dc556d0ddb60ae!2sZambak%20Makine%20Boya%20Ekipmanlari%20Sanayi%20Ve%20Ticaret%20Pazarlama%20Limited%20%C5%9Eirketi!5e0!3m2!1str!2str!4v1641385020366!5m2!1str!2str"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          iframe />
      </div>
      <div class="col-6">
        <h4 class="heading-primary"> 
        İletişim Bilgileri 
        </h4>
      <div class="left w100y f14">
        <ul class="list list-icons list-icons-style-3 mt-xlg">
          <li class="mb15">
            <div>
               <p class="f16"> 
               <i class="fa fa-map-marker" aria-hidden="true">
                 </i> 
                 <strong>Adres:
                   </strong> 
                 </p>
                </div>
            <div>
              <p>
                
              Anbar, 31. Cd. NO:86, 38070 Melikgazi/Kayseri
                
              </p>
            </div>
          </li>
          <li>
            <div>
               <p class="f16">
                  <i class="fa fa-phone">
                    </i>
                     <strong>Sabit Telefon:
                       </strong> 
                       </p> 
                       </div>
<div>
   <p> 
     <a href="tel:0(352) 503 08 43">
     0(352) 503 08 43
       </a> 
       </p> 
       </div>
</li>
<li>
            <div>
               <p class="f16">
                  <i class="fa fa-phone">
                    </i>
                     <strong>Cep Telefon:
                       </strong> 
                       </p> 
                       </div>
<div>
   <p> 
     <a href="tel:0(542) 570 19 21">
     0(542) 570 19 21
       </a> 
       </p> 
       </div>
</li>
<li>
<div> 
  <p class="f16"> 
  <i class="fa fa-fax">
    </i> <strong>Fax:
      </strong> 
      </p> 
      </div>
<div> 
  <p> 
    <a href="0(352) 503 08 43">
    0(352) 503 08 43
      </a> 
      </p> 
      </div>
</li>
<li>
<div>
   <p class="f16">
      <i class="fa fa-mobile">
        </i>
         <strong> E-Posta:
           </strong>
            </p> 
            </div>
<div>
   <p>
     
   <a id="cpmIcerik_contact1_hlEposta" 
      href="mailto:mervepompa@gmail.com" 
      target="_blank">
      mervepompa@gmail.com
    </a>
        
         </p> 
         </div>
</li>
</ul>


</div>

</div>

</div>

</div>
<Footer/>
</div>


                      

    








                                



                            

                              





      
     

    );
}

export default bilgilendirme;
