import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

import Sektor from '../Sektor';


function Home() {
  return (
    <>
    
      <HeroSection />
      
      <Cards />

      

      <Sektor/>

      <Footer />

      
    </>
  );
}

export default Home;