import React, { useState, useEffect, Image, useImperativeHandle} from 'react';
import { MenuItems } from './MenuItem';
import { Link } from 'react-router-dom';

import './Dropdown.css'

function  Dropdown() {

    return (
    <>
        <h1>yo</h1>
    </>
    );
}

export default Dropdown;