import React from 'react';
import '../../App.css';
import pic from "../hak.jpg";




import Footer from '../Footer';

 function Products() {
  return (
    
  
  <div class="left w100y mb15 icerik-kapsa" >
    <h4 class="heading-primary">Hakkımızda</h4>
    <p></p>
    <img src={pic} 
                width="440px"
                 height="293px" 
                 float="left"
                  margin-left="5px"
                  margin-right="5px"
                 className="ml-3"
                 
                alt="" />

   
      <p >1996 yılında Kayseri Organize Sanayi Bölgesinde kurulan Zambak Makine ülkemizde ve bölgemizde faaliyet gösteren hava püskürtme makineleri, basınçlı boya tankları, diyaframlı boya pompaları, havasız boya tabancaları vb farklı ürünlerde satış yapan müşterileri için dünyanın saygın üreticilerinden tedarik ederek ticari faaliyetlerine başlamıştır.
      </p>
      <p>
      </p>
      <p>
      </p>
              
      <Footer/>
  </div>
  
  
  
  );
}
export default Products;
