import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <p><h1> DİYAFRAMLI POMPALAR</h1></p>
          <ul className='cards__items'>
          <CardItem
              src='images/zambak_pompa.jpeg'
              text=' Warder Diyaframlı Pompa'
              path='/services'
            />
          
          
          <CardItem
              src='images/RP-8411-Diyaframlı-Pompa.jpg'
              text=' RP 8411 Diyaframlı Pompa'
              path='/services'
            />
             <CardItem
              src='images/Sagola-Dm-01-400-Diyaframlı-Boyama-Seti.jpg'
              text='Sagola Dm 01/400 Diyaframlı Boyama Seti'
              
              path='/services'
            />
          
          </ul>
          <ul className='cards__items'>
                        
          <CardItem
              src='images/Graco-Triton-308-Boya-Pompası.jpg'
              text='Graco Triton 308 Boya Pompası'
              
              path='/services'
            
                          
            />
              
           
             <CardItem
              src='images/Wagner-Zip-52-Diyaframlı-Pompa.jpg'
              text='WAGNER ZİP 52 DİYAFRAMLI BOYA POMPASI
              '
              
              path='/services'
            />
             <CardItem
              src='images/Carox-K56-Cift-Diyaframli-Boya-Pompasi.jpg'
              text='CAROX K56 ÇİFT DİYAFRAMLI BOYA POMPASI'
              
              path='/services'
            />
           
            <CardItem
              src='images/K-24-Diyaframlı-Boyama-Seti.jpg'
              text='K 24 Diyaframlı Boyama Seti'
              
              path='/services'
            />
            </ul>
            <p><h1> AİRLESS ELEKTRİKLİ BOYA MAKİNALARI</h1></p>
            <ul className='cards__items'>
            
              
           
            <CardItem
             src='images/Carox-6325-Elektrikli-Airless-Pompa.jpg'
             text='Carox 6325 Airless Elektrikli Pompa'
             
             path='/services'
           />
            <CardItem
             src='images/Carox-6335-Elektrikli-Airless-Pompa.jpg'
             text='Carox 6335 Airless Elektrikli Pompa'
             
             path='/services'
           />
          
           <CardItem
             src='images/Dp-A-395-Airless-Elektrikli-Boya-Makinası.jpg'
             text='Dp-A 395 Airless Elektrikli Boya Makinası'
             
             path='/services'
           />

           </ul>
           <ul className='cards__items'>
            
              
           
             <CardItem
              src='images/Dp-A-495-Elektrikli-Boya-Makinası.jpg'
              text='Dp-A 495 Elektrikli Boya Makinası'
              
              path='/carox.js'
            />
             <CardItem
              src='images/Graco-King-xl-70-Elektrikli-Airless-Boya-Makinası.jpg'
              text='Graco King XL 70 Airless Elektrikli Boya Makinası'
              
              path='/services'
            />
            <CardItem
              src='images/pompa.jpeg'
              text='Airless Elektrikli Boya Makinası'
              
              path='/carox.js'
            />
           </ul>
           <p><h1> AİRLESS HAVALI BOYA MAKİNALARI</h1></p>
            <ul className='cards__items'>
            
              
           
            <CardItem
             src='images/Carox-301-Airless-Havalı-Boya-Makinası.jpg'
             text='Carox 301 Airless Havalı Boya Makinası'
             
             path='/services'
           />
            <CardItem
             src='images/Graco-301-Airless-Havalı-Boya-Makinası.jpg'
             text='Graco 301 Airless Havalı Boya Makinası'
             
             path='/services'
           />
          
           <CardItem
             src='images/Graco-701-Airless-Havalı-Boya-Makinası.jpg'
             text='Graco 701 Airless Havalı Boya Makinası'
             
             path='/services'
           />

           </ul>
           <ul className='cards__items'>
            
              
           
             <CardItem
              src='images/Kst-xt-681-Airles-Boya-Pompasi.jpg'
              text='KST-XT 681 Airles Boya Makinası'
              
              path='/carox.js'
            />
             <CardItem
              src='images/Kst-301-Airles-Boya-Makinasi.jpg'
              text='KST 301 Airles Boya Makinası'
              
              path='/services'
            />
           </ul>
           <p><h1> AİRMİX BOYA MAKİNALARI</h1></p>
           <ul className='cards__items'>
                         
            <CardItem
             src='images/Wagner-1840-Boya-Pompası.jpg'
             text='Wagner 1840 Boya Makinası'
             
             path='/carox.js'
           />
            <CardItem
             src='images/Graco-301-Boya-Pompası.jpg'
             text='Graco 301 Boya Makinası'
             
             path='/services'
           />
          </ul>
          <p><h1>BASINÇLI BOYA TANKLARI</h1></p>
           <ul className='cards__items'>
                         
            <CardItem
             src='images/Basınçlı-Boya-Tankı-2-Lt.jpg'
             text='Basınçlı Boya Tankı 2 Lt'
             
             path='/carox.js'
           />
            <CardItem
             src='images/Basınçlı-Boya-Tankı-10-Lt.jpg'
             text='Basınçlı Boya Tankı 10 Lt'
             
             path='/services'
           />
            <CardItem
             src='images/Basınçlı-Boya-Tankı-20-Lt.jpg'
             text='Basınçlı Boya Tankı 20 Lt'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Basınçlı-Boya-Tankı-40-Lt.jpg'
             text='Basınçlı Boya Tankı 40 Lt'
             
             path='/services'
           />
            <CardItem
             src='images/Basınçlı-Boya-Tankı-60-Lt.jpg'
             text='Basınçlı Boya Tankı 60 Lt'
             
             path='/services'
           />
           </ul>
           <p><h1>BOYA TABANCALARI</h1></p>
           <ul className='cards__items'>
           <CardItem
             src='images/Wagner-4700-Boya-Tabancası.jpg'
             text='Wagner 4700 Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/Graco-G40-Boya-Tabancası.jpg'
             text='Graco G40 Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/Graco-XTR-5-Airless-Boya-Tabancası.jpg'
             text='Graco XTR-5 Airless Boya Tabancası'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Devilbiss-Gti-Hd-Boya-Tabancasi.jpg'
             text='Devilbiss Gti Hd Basınçlı Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/DevilBiss-Boya-Tabancasi.jpg'
             text=' DevilBiss Compliant Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/Devilbiss-Compliant-Basincli-Boya-Tabancasi.jpg'
             text='Devilbiss Compliant Basınçlı Boya Tabancası'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Devilbiss-Compliant-Ust-Depolu-Boya-Tabancasi.jpg'
             text='Devilbiss Compliant Üst Depolu Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/Devilbiss-Convential-Boya-Tabancasi.jpg'
             text='Devilbiss Convential Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/Carox-506-A-Boya-Tabancası.jpg'
             text='Carox 506 A Boya Tabancası'
             
             path='/services'
           />
           
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/boya_tabancası.jpeg'
             text=' Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/boya_tabancası2.jpeg'
             text=' Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/boya_tabancası3.jpeg'
             text=' Boya Tabancası'
             
             path='/services'
           />
           </ul>
           <ul  className='cards__items'>
           <CardItem
             src='images/Carox-506-P-Boya-Tabancası.jpg'
             text='Carox 506 P Boya Tabancası'
             
             path='/services'
           />
            <CardItem
             src='images/boya_tabancası4.jpeg'
             text=' Boya Tabancası'
             
             path='/services'
           />

           </ul>
           <p><h1>TOZ BOYA MAKİNALARI</h1></p>
           <ul className='cards__items'>
           <CardItem
             src='images/Micron-Boya-Robot-Takımı.jpg'
             text='Micron Boya Robot Takımı'
             
             path='/services'
           />
            <CardItem
             src='images/Elektrostatik-Toz-Boya-EleğiM.jpg'
             text='Micron Elektrostatik Toz Boya Eleği'
             
             path='/services'
           />
            <CardItem
             src='images/Micron-B300-Elektrostatik-Toz-Boya-Cihazı.jpg'
             text='Micron B300 Toz Boya Makinası'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Toz-Boya-Robotu.jpg'
             text='Micron Toz Boya Robotu'
             
             path='/services'
           />
            <CardItem
             src='images/Toz-Boya-Geri-Dönüşüm-Makinası.jpg'
             text='Micron Toz Boya Geri Dönüşüm Makinası'
             
             path='/services'
           />
            <CardItem
             src='images/Gema-Toz-Boya-Cihazı.jpg'
             text=' Gema Toz Boya Makinası'
             
             path='/services'
           />
           <CardItem
             src='images/Wagner-Toz-Boya-Tabancaları.jpg'
             text='Wagner Toz Boya Makinası'
             
             path='/services'
           />
           </ul>
           <p><h1>TOZ BOYA YEDEK PARÇALARI</h1></p>
           <ul className='cards__items'>
           <CardItem
             src='images/Pem-C4-elektrod-çeşitleri.jpg'
             text='PEM C4 Elektrod Çeşitleri'
             
             path='/services'
           />
            <CardItem
             src='images/PMC2-Elektrot.jpg'
             text='PMC 2 Elektrot'
             
             path='/services'
           />
            <CardItem
             src='images/Toz-Boya-Elektrotları.jpg'
             text='Toz Boya Elektrotları'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Wagner-Toz-Boya-Emiş-Enjektörü.jpg'
             text='Wagner Toz Boya Emiş Enjektörü'
             
             path='/services'
           />
            <CardItem
             src='images/Wagner-Pem-C4-Elektrodu.jpg'
             text='Wagner Pem C4 Elektrod'
             
             path='/services'
           />
            <CardItem
             src='images/Wagner-enjektör-memesi.jpg'
             text='Wagner Enjektör Memesi'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Tribo-Toz-Boya-Tabancası-yedek-parçaları.jpg'
             text='Tribo Toz Boya Tabancası Yedek Parçaları'
             
             path='/services'
           />
            <CardItem
             src='images/Toz-Boya-Hortum-Çeşitleri.jpg'
             text='Toz Boya Hortum Çeşitleri'
             
             path='/services'
           />
           </ul>
           <p><h1>TRANSFER POMPALARI</h1></p>
           <ul className='cards__items'>
           <CardItem
             src='images/Rp-A25-Transfer-Pompası.jpg'
             text='Rp A25 Transfer Pompası'
             
             path='/services'
           />
            <CardItem
             src='images/Wagner-Zip-52-Transfer-Pompası.jpg'
             text='Wagner Zip 52 Transfer Pompası'
             
             path='/services'
           />
            <CardItem
             src='images/diyaframlı_pompa.jpeg'
             text=' Warder Diyaframlı Transfer Pompası'
             
             path='/services'
           />
            <CardItem
             src='images/diyaframlı_pompa2.jpeg'
             text='Warder Diyaframlı Transfer Pompası'
             
             path='/services'
           />
           </ul>
           <p><h1>YAŞ BOYA MAKİNALARI YEDEK PARÇALARI</h1></p>
           <ul className='cards__items'>
           <CardItem
             src='images/Airless-Boya-Tabanca-Uzatması.jpg'
             text='Airless Boya Tabanca Uzatması'
             
             path='/services'
           />
            <CardItem
             src='images/Boya-Emiş-Filitresi.jpg'
             text='Boya Emiş Filtresi'
             
             path='/services'
           />
             <CardItem
             src='images/Graco-Triton-308-Diyaframı.jpg'
             text='Graco Triton 308 Diyaframı'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Gövde-Filtreleri-30-60-100-Mesh.jpg'
             text='Gövde Filtreleri 30-60-100-Mesh'
             
             path='/services'
           />
            <CardItem
             src='images/Düşük-Basınçlı-Ayar-Regülatörü.jpg'
             text='Düşük Basınçlı Ayar Regülatörü'
             
             path='/services'
           />
             <CardItem
             src='images/İkili-Hava-ve-Boya-Hortumu.jpg'
             text='İkili Hava ve Boya Hortumu'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Meme-Temizleme-İğnesi.jpg'
             text='Meme Temizleme İğnesi'
             
             path='/services'
           />
            <CardItem
             src='images/Memeler-ve-Kelebekler.jpg'
             text='Memeler ve Kelebekler'
             
             path='/services'
           />
             <CardItem
             src='images/Tamir-Keçe-Takımları.jpg'
             text='Tamir Keçe Takımları'
             
             path='/services'
           />
           </ul>
           <ul className='cards__items'>
           <CardItem
             src='images/Titan-450-E-Keçe-Takımı.jpg'
             text='Titan 450 E Keçe Takımı'
             
             path='/services'
           />
            <CardItem
             src='images/Wagner-Kalem-Filtre.jpg'
             text='Wagner Kalem Filtre'
             
             path='/services'
           />
             <CardItem
             src='images/Wagner-Pompa-Tamir-Takımı.jpg'
             text='Wagner Pompa Tamir Takımı'
             
             path='/services'
           />
            <CardItem
             src='images/Wagner-Zip-52-Diyaframı.jpg'
             text='Wagner Zip 52 Diyaframı'
             
             path='/services'
           />
           </ul>
           <p><h1>ZIMPARA MAKİNALARI VE EKİPMANLARI</h1></p>
           <ul className='cards__items'>
           <CardItem
             src='images/Carox-086S-Orbital-Zımpara-Makinası.jpg'
             text='Carox 086S Orbital Zımpara Makinası'
             
             path='/services'
           />
            <CardItem
             src='images/Carox-333-3H-Orbital-Havalı-Zımpara-Makinası.jpg'
             text='Carox 333-3H Orbital Havalı Zımpara Makinası'
             
             path='/services'
           />
             <CardItem
             src='images/Carox-DWS-600-Duvar-Tavan-Parke-Zımpara-Makinası.jpg'
             text='Carox DWS 600 Duvar-Tavan-Parke Zımpara Makinası'
             
             path='/services'
           />
            <CardItem
             src='images/Zımpara.jpg'
             text='Zımpara'
             
             path='/services'
           />
           </ul>
           
        </div>
      </div>
    </div>
  );
}

export default Cards;