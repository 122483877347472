
import React from 'react';
import { Link } from 'react-router-dom';
 function Hakkımızda() {
    
     return (
   <div>
           
   </div>
     ) 
}
export default Hakkımızda;