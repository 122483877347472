import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';



function HeroSection() {
  return (
    
    <div className='hero-container'>
       <video src='./videos/video-2.mp4' autoPlay loop muted /> 
     
      <h1>ZAMBAK MAKİNE BOYA MAKİNELERİ BAKIM ONARIM & SATIŞ</h1>
      
      <div className='hero-btns'>
        {/* <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
         Hakkımızda
        </Button>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          BERABER ÇALIŞALIM <i  />
        </Button> */}
      </div>
    </div>
  );
}

export default HeroSection;