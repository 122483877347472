import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Sektor() {
  return (
    <div className='cards'>
      
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <p><h1> SEKTÖRLERİMİZ </h1></p>
          <ul className='cards__items'>
         
          
          
          <CardItem
              src='images/makina-image.jpg'
              text='MAKİNA'
              path='/services'
            />
             <CardItem
              src='images/metal-ve-celik-image.jpg'
              text='METAL VE ÇELİK'
              
              path='/services'
            />
          
             
            <CardItem
              src='images/otomotiv-image.jpg'
              text='OTOMOTİV'
              
              path='/services'
            
                          
            />
          </ul>
          <ul className='cards__items'>
          <CardItem
              src='images/insaat-image.jpg'
              text='İNŞAAT'
              path='/services'
            />
             <CardItem
              src='images/gemi-ve-yat-image.jpg'
              text='GEMİ ve YAT'
              
              path='/services'
            />
          
             
            <CardItem
              src='images/ahsap-image.jpg'
              text='AHŞAP'
              
              path='/services'
            
                          
            />
            
           </ul>
        </div>
      </div>
    </div>
  );
}

export default Sektor;