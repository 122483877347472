import React from 'react';
import '../../App.css';
import pic from '../Carox-K56-Cift-Diyaframli-Boya-Pompasi.jpg';
import Footer from '../Footer';
import CardItem from '../CardItem';
import HeroSection from '../HeroSection';

import bakın from '../bakın';


 function carox() {
  



  return (
    <>
    
     
      
     

    <div md-col-12 class=" w100y mb15 icerik-kapsa" >
    <h4 class="heading-primary">CAROX K56 ÇİFT DİYAFRAMLI BOYA POMPASI</h4>
    <p></p>
    <img  src={pic} 
                width="655" 
                height="655" 
                 float="center"
                  margin-left="5px"
                  margin-right="5px"
                 className="ml-3"
                 
                alt="" />
                <div class="box"></div>

   
      <p >
      Bu pompa düşük basınçlarda birçok sıvı çeşitleriyle çalışmaya elverişlidir.

Özellikleri

Su ve solvent bazlı ürünlerle kullanılabilir. Membran ve sıvıyla temas eden bütün ürünler teflondandır.
Otomatik veya manuel tabancalarla sonkat atımında, elektrostatik sistemlerle, HVLP veya konvansiyonel ünitelerle kullanılabilir.
Küçük ölçüsü ve hafifliğine rağmen çok yüksek akım hızı.
Daha hızlı sirkülasyon, daha düşük hava kullanımı ve daha az gürültü.
Kolay operasyon ve kolay temizlik.
Kaplama arayüzü boyutu : 3/4inch
Max . Hava basıncı : 7bar
Malzeme Girişi ve Çıkışı : 3/4 ” NPT
Hava Girişi ve Çıkışı : 1/4 ” NPT
Minimum başlangıç ​​basıncı: Planar valfi: 1.6bar / Alçak basınç valfi: 0.5bar
Max . Malzeme Akışı: 14.88G.P.M ( 56LPM )
Max . Hava Tüketimi: 380NL/Min
Max . Tane Çapı: 3 mm
Emiş: 6.4m ( ıslak) , 3.7M (kuru )
Diyafram Çapı: 142mm
      </p>
      <p>
      </p>
      <p>
      </p>
   

     
     
     <Footer/>
  </div>
      
            
              
           
      

      
    </>
  );
}













    
    
    
       

    
    

     
 

export default carox    ;
